import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { getRowIdFromRowModel } from '@mui/x-data-grid/internals';
import { Box, Button, Dialog, DialogContent, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import OrderTable from './OrderTable';
import Title from './Title';
import { TransitionProps } from '@mui/material/transitions';
import useWindowDimensions from './useWindowDimensions';
import { EquityOrderType, ErrorLogType, projectMembershipType, TransactionType } from './Interfaces';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import EquityOrder from './EquityOrder';
import useState from 'react-usestateref';
import ValuationChart from './ValuationChart';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
    children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MarketView({projectId, currency, ProjectsMemberships, Transactions, setDatabaseStatusRequest, setDatabaseErrorDesc}
    :{projectId:string, currency:string, ProjectsMemberships:projectMembershipType[], 
        Transactions:TransactionType[], setDatabaseStatusRequest:Function, setDatabaseErrorDesc:Function}) {    

    const [user, loading, error] = useAuthState(auth);
    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";

    const [openCreateOrderPanel, setopenCreateOrderPanel] = React.useState(false);
    const [EquityOrders, setEquityOrders] = React.useState<EquityOrderType[]>();

    async function ErrorHandler(error:Error, SourceFunction:string)
    {
      let errorLog:ErrorLogType = {file: "MarketView", message: error.message, userId:currentUserId??"", date:new Date(), sourceFunction: SourceFunction, }
      setDatabaseStatusRequest("DatabaseError");
      setDatabaseErrorDesc(errorLog);  
    }

    function loadDB(){
        const fetchMarketOrders = async () => {
          const q = query(collection(db, "EquityOrders"), where("projectId", "==", projectId), where("status", "!=", "Deleted"));
          const querySnapshot = await getDocs(q);
            let MarketOrders:EquityOrderType[]=[];
            querySnapshot.docs.forEach(doc=>{
              let order:EquityOrderType;
              order=doc.data();
              order.id = doc.id;
              order.isDirty = false;
              MarketOrders.push(order);
            })
            setEquityOrders(MarketOrders);
          }
          fetchMarketOrders()
          .then(()=>{setDatabaseStatusRequest("OK");});
    
      }

      React.useEffect(
        ()=>{
            loadDB();
    },[]);


    return (
        <Paper elevation={0} 
        sx={{
            py: 2,
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            alignItems:'center',
        }}>
  
        <Title >Equity Market</Title>

        <OrderTable EquityOrders={EquityOrders!} 
                    currency={currency}
                    OutstandingShares={Transactions
                    .filter(transaction=>(transaction.type=="equity"))
                    .filter(transaction=>(transaction.marketType=="primary"))    
                    .reduce((sum:number, value)=>{sum =sum+(value.value??0); return sum;}, 0)}
                    setDatabaseStatusRequest={setDatabaseStatusRequest} 
                    setDatabaseErrorDesc={setDatabaseErrorDesc}
                    loadDBFn={loadDB}/>

        <Button sx={{textTransform: 'none', fontSize:12, minWidth:'15%', m:4}} variant="contained" 
        onClick={() =>{setopenCreateOrderPanel(true)}}>Create an Order</Button>

        <Dialog
            maxWidth="lg"
            open={openCreateOrderPanel}
            TransitionComponent={Transition}
            onClose={()=>{setopenCreateOrderPanel(false); loadDB();}}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>

                <ValuationChart Transactions={Transactions} Orders={EquityOrders!}/>

                <EquityOrder 
                projectId={projectId} 
                currency={currency}
                currentOwnership={Transactions.reduce(
                    (sum:number, transaction)=>
                        {
                            if(transaction.destinationId==currentUserId && transaction.type=="equity")
                            sum =sum+(transaction.value??0); return sum;
                        }, 0)}
                closecallbackfn={()=>{setopenCreateOrderPanel(false); loadDB();}}
                setDatabaseStatusRequest={setDatabaseStatusRequest} 
                setDatabaseErrorDesc={setDatabaseErrorDesc}/>
            </DialogContent>
        </Dialog>

        </Paper>
      );
    }