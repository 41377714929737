import { Height, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Autocomplete, AutocompleteChangeDetails, Avatar, Box, Button, Checkbox, Chip, Container, FormControlLabel, FormGroup, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, MobileStepper, Paper, Switch, TextField, TextareaAutosize, ToggleButton, ToggleButtonGroup, Tooltip, Typography, createFilterOptions, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { EquityComponentType, CategoryType, ProjectType, SkillType, projectMembershipType, UserType, TransactionType } from "./Interfaces";
import useState from 'react-usestateref';
import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useHistory, useParams } from "react-router-dom";
import TargetEquityChart from "./TargetEquityChart";
import UserAvatar from "./UserAvatar";
import DeleteIcon from '@mui/icons-material/Delete';
import { set } from "date-fns";
import { useAuthState } from "react-firebase-hooks/auth";
import Project from "./Project";
import InitEquityAssignmentChart from "./InitEquityAssignmentChart";


export default function CreateOrEditProject({IsStoryNotQuick, IsCreateNotEdit, setDatabaseStatusRequest, setDatabaseErrorDesc}:
    {IsStoryNotQuick:boolean, IsCreateNotEdit:boolean, setDatabaseStatusRequest:Function, setDatabaseErrorDesc:Function}){

    const history = useHistory();

    let { id } = useParams<{id: string}>();

    const theme = useTheme();

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  

    const [user, loading, error] = useAuthState(auth);
    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";

    const [project, setProject, projectRef] = useState<ProjectType>({});
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [newCategory, setNewCategory, newCategoryRef] = useState<CategoryType>();
    const [skills, setSkills] = useState<SkillType[]>([]);
    const [newSkills, setnewSkills, newSkillsRef] = useState<SkillType[]>([]);
    const [ProjectsMemberships, setProjectsMemberships, ProjectsMembershipsRef] = useState<projectMembershipType[]>([]);
    const [Users, setUsers] = useState<UserType[]>([]);
    const [StepTooLargeErrorState, setStepTooLargeErrorState] = useState<boolean>(false);
    const [SelectedEquityComponent, setSelectedEquityComponent] = useState<String>("Core Idea");
    const [Transactions, setTransactions, TransactionsRef] = useState<TransactionType[]>([]);

    const [InitializationSequence, setInitializationSequence, InitializationSequenceRef] = useState<number>(0);

    const [checked, setChecked] = React.useState([1]);

    const handleToggle = (value: number) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex == -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      setChecked(newChecked);
    };
  

    function capitalizeFirstLetter(string:string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const filter = createFilterOptions();

    
    async function saveDBCreate(e:any)
    {
        e.preventDefault();

        if(project.isDirty==true)
        {
            await addDoc(collection(db, "Projects"), project )
            .then((docRef)=>
            {
                setProject({...project, id: docRef.id});
                return docRef;
            })
            .then((docRef)=>
            {
                setProjectsMemberships(ProjectsMemberships?.map(membership=>{
                    membership.projectId = docRef.id;
                    return membership;
                }))
                return docRef;
            })

            .then ((docRef) =>
            {
                setTransactions(TransactionsRef.current.map((transaction) =>
                    {
                        if(transaction.sourceType=="project")
                        {
                            transaction.sourceId = docRef.id;
                        }
                        return transaction;
                    }                
                ))
            })

            .then(()=>
            {
                ProjectsMemberships?.forEach(async membership=>{
                    if(membership.isDirty==true)
                    {
                        await addDoc(collection(db, "ProjectsMemberships"), membership );
                    }
                });
            }).catch(()=>{setDatabaseStatusRequest("Error"); setDatabaseErrorDesc("Error saving project");})
            
            .then(()=>{

                if(TransactionsRef.current.length!=0)
                    {
                        TransactionsRef.current.forEach(async transaction=>{
                            await addDoc(collection(db, "Transactions"), transaction );
                        })
                    }
                    
            }).catch(()=>{setDatabaseStatusRequest("Error"); setDatabaseErrorDesc("Error saving Transactions");})
            ;
        }


        if(newCategory!=undefined)
        {
            await addDoc(collection(db, "Categories"), newCategory ).catch(()=>{setDatabaseStatusRequest("Error"); setDatabaseErrorDesc("Error saving new Category");});
        }

        if(newSkills.length!=0)
        {
            newSkills.forEach(async skill=>{
                await addDoc(collection(db, "Skills"), skill ).catch(()=>{setDatabaseStatusRequest("Error"); setDatabaseErrorDesc("Error saving new Skill");});
            })
        }
    
        history.push('/Home/Projects');
    }


    async function saveDBEdit()
    {
        let activeCycle=false;
        if(projectRef.current.isDirty==true)
        {
            activeCycle=true;
                await setDoc(doc(db, "Projects", id), projectRef.current).then(()=>{setProject({...projectRef.current, isDirty:false});})
            .catch((e)=>{setDatabaseStatusRequest("Error"); setDatabaseErrorDesc("Error updating project");}); 
        }

        ProjectsMembershipsRef.current.forEach(async (membership,i)=>{
            if(membership.isDirty==true)
            {   
                activeCycle=true;
                if(membership.id==undefined)
                {
                    await addDoc(collection(db, "ProjectsMemberships"), membership ).then(()=>{
                        setProjectsMemberships(ProjectsMembershipsRef.current?.map((membership,j)=>{if(i==j){membership.isDirty=false;}return membership;})
                    )}).catch(()=>{setDatabaseStatusRequest("Error"); setDatabaseErrorDesc("Error updating project memberships");});
                }
                else
                {
                    await setDoc(doc(db, "ProjectsMemberships", membership.id??""), membership).then(()=>{
                        setProjectsMemberships(ProjectsMembershipsRef.current?.map((membership,j)=>{if(i==j){membership.isDirty=false;}return membership;})
                    )}).catch(()=>{setDatabaseStatusRequest("Error"); setDatabaseErrorDesc("Error updating project memberships");});
                }
            }
        })

        if(activeCycle==false)
        {
            if(projectRef.current.isDirty==true)
            {
                setDatabaseStatusRequest("Error");
                setDatabaseErrorDesc("Error updating project");
            }
            else
            {
                setDatabaseStatusRequest("OK");
            }
    
            if(ProjectsMembershipsRef.current.some(membership=>membership.isDirty==true))
            {
                setDatabaseStatusRequest("Error");
                setDatabaseErrorDesc("Error updating project memberships");
            }
        }
    }

    
    function loadDB() {
        const fetchProject = async () => {
            if(id)
            {
                const docRef = doc(db, "Projects", id);
                const docSnap = await getDoc(docRef);
                
                if (docSnap.exists()) {
                    let Project:ProjectType;
                    Project=docSnap.data();
                    Project.id = docSnap.id;
                    Project.isDirty = false;
                    setProject(Project);
                } else {
                    // doc.data() will be undefined in this case
                }        
            }
        }
        fetchProject().catch(()=>{setDatabaseStatusRequest("Error");  setDatabaseErrorDesc("Error reading project");}); 
        
        if(id!=undefined)
        {
            const fetchProjectsMemberships = async () => {
                const q = query(collection(db, "ProjectsMemberships"), where("projectId", "==", id));
                const querySnapshot = await getDocs(q);
                  let projectsMemberships:projectMembershipType[]=[];
                  querySnapshot.docs.forEach(doc=>{
                    let projectMembership:projectMembershipType;
                    projectMembership=doc.data();
                    projectMembership.id = doc.id;
                    projectMembership.isDirty = false;
                    projectsMemberships.push(projectMembership);
                    console.log(projectMembership);
                  })
                  setProjectsMemberships(projectsMemberships)
                }
            fetchProjectsMemberships().catch(()=>{setDatabaseStatusRequest("Error");  setDatabaseErrorDesc("Error reading project memberships")});  
        }

        const fetchCategories = async () => {
        const querySnapshot = await getDocs(collection(db, "Categories"));
        let Categories:CategoryType[]=[];
        querySnapshot.docs.forEach(doc=>{
            let Category:CategoryType;
            Category=doc.data();
            Category.id = doc.id;
            Categories.push(Category);
        })
        Categories.sort((a:CategoryType, b:CategoryType) => a.title?.localeCompare(b.title??"")??0);
        setCategories(Categories)
        }
        fetchCategories().catch(()=>{setDatabaseStatusRequest("Error");  setDatabaseErrorDesc("Error reading Categories")});  


        const fetchSkills = async () => {
        const querySnapshot = await getDocs(collection(db, "Skills"));

        let Skills:SkillType[]=[];
        querySnapshot.docs.forEach(doc=>{
            let Skill:SkillType;
            Skill=doc.data();
            Skill.id = doc.id;
            Skills.push(Skill);
        })
        Skills.sort((a:SkillType, b:SkillType) => a.title?.localeCompare(b.title??"")??0);
        setSkills(Skills);
        }
        fetchSkills().catch(()=>{setDatabaseStatusRequest("Error");  setDatabaseErrorDesc("Error reading Skills")});  

        const fetchUsers = async () => {
        const querySnapshot = await getDocs(collection(db, "Users"));

        let Users:UserType[]=[];
        querySnapshot.docs.forEach(doc=>{
            let User:UserType;
            User=doc.data();
            Users.push(User);
        })
        setUsers(Users)
        }
        fetchUsers().catch(()=>{setDatabaseStatusRequest("Error");  setDatabaseErrorDesc("Error reading Users")});   
    }


    function handleSetVisibility(e:any)
    {

        if(project?.visibility=="public" || project?.visibility==undefined)
        {
            setProject({...project, visibility:"private", isDirty:true});
            if(!IsCreateNotEdit){setDatabaseStatusRequest("Sync");}        
        }
        else if(project?.visibility=="private")
        {
            setProject({...project, visibility:"public", isDirty:true});
            if(!IsCreateNotEdit){setDatabaseStatusRequest("Sync");}        
        }
    }


    function InitializeProject()
    {
        if(InitializationSequenceRef.current==0)
        {
            setProject({...project, equityComponentList:
                [
            {name:"Core Idea", targetEquityShares:100000, type:'outstanding', locked:false} as EquityComponentType,
            {name:"IP", targetEquityShares:150000, type:'outstanding', locked:false} as EquityComponentType,
            {name:"Cash", targetEquityShares:150000, type:'cash', cash:100000, locked:false} as EquityComponentType,
            {name:"Management", targetEquityShares:150000, type:'reserved', locked:false} as EquityComponentType,
            {name:"Development", targetEquityShares:250000, type:'reserved', locked:false} as EquityComponentType,
            {name:"Marketing", targetEquityShares:100000, type:'reserved', locked:false} as EquityComponentType,
            {name:"Sales", targetEquityShares:100000, type:'reserved', locked:false} as EquityComponentType],
            
            authorizedShares:1000000,
            currentValuation:100000,
            currency:"EGP",
            visibility:"public",
    
            isDirty:true}
            );    

            setInitializationSequence(1);
        }

        else if(InitializationSequenceRef.current==1)
        {
            setProjectsMemberships([{userId: currentUserId, projectId:project.id, 
                    memberName: currentUserName, status:"active", role:'Admin', isDirty:true}]);
        
                setInitializationSequence(2);    
        }

        else if(InitializationSequenceRef.current==2)
        {
            setInitializationSequence(3);
        }

        else if(InitializationSequenceRef.current==3)
        {
            ResetTargetEquities(true);

            setInitializationSequence(4);
        }

    }



    function increaseEquity(equityCompName:string, step:number)
    {
        step = 10**step * (projectRef.current.equityComponentList?.filter((comp)=>(comp.locked==false)).length!-1);

        if(step < (projectRef.current.authorizedShares!))
        {
            setStepTooLargeErrorState(false);

            if(projectRef.current.equityComponentList?.find(comp=>(comp.name==equityCompName))?.locked==false)
            {
                setProject({...projectRef.current, 
                    equityComponentList:
                    projectRef.current.equityComponentList?.map(
                        (comp, idx, list)=>
                        {
                            if(comp.name==equityCompName && comp.locked==false)
                            {
                                comp.targetEquityShares = comp.targetEquityShares!+step;
                            } 
                            if(comp.name!==equityCompName && comp.locked==false)
                            {
                                comp.targetEquityShares = Math.max(0, comp.targetEquityShares!-(step/(list.filter((comp)=>(comp.locked==false)).length-1)));
                            } 
                            return comp;
                        })
                        //.filter((comp:EquityComponentType)=>(comp.targetEquityShares!>0))
                        , isDirty:true})
            }
        }
        else
        {
            setStepTooLargeErrorState(true);
        }
    }

    function toggleLockEquityComponent(equityCompName:string)
    {
        setProject({...projectRef.current, 
            equityComponentList:
            projectRef.current.equityComponentList?.map(
                (comp, idx, list)=>
                {
                    if(comp.name==equityCompName)
                    {
                        comp.locked = !comp.locked;
                    }
                    return comp;
                })
                , isDirty:true})
    }


    function ResetTargetEquities(TotalSharesNotOutstanding:boolean)
    {
            let targetShares = projectRef.current.authorizedShares!;
            let sharesPerComp = Math.ceil(targetShares/(projectRef.current.equityComponentList!.length));
            setProject({...projectRef.current, 
                equityComponentList:
                projectRef.current.equityComponentList?.map(
                    (comp, idx, list)=>
                    {
                        if(targetShares>sharesPerComp)
                        {
                            comp.targetEquityShares = sharesPerComp;
                            targetShares -= sharesPerComp;
                        }
                        else
                        {
                            comp.targetEquityShares = targetShares;
                        }

                        comp.locked = false;
                        return comp;
                    })
                    , isDirty:true});
    
                    setStepTooLargeErrorState(false);    
    }

    function PeriodicFunction()
    {   
        saveDBEdit();
    }

    function setEquityCompFocus(targetcomp:string)
    {   
        console.log(targetcomp)
        if(targetcomp!= null)
        {
            setProject({...project, equityComponentList:project.equityComponentList?.map((comp, idx, list)=>{
                if(comp.name==targetcomp)
                {
                    comp.focus=true;
                }
                else
                {
                    comp.focus=false;
                }
                return comp;
            })})                                
        }
    }

    useEffect(
        ()=>{
            loadDB();
        },[]);
    
    useEffect(
        ()=>{
            const interval = setInterval(() => {
                if(!IsCreateNotEdit)
                {
                    PeriodicFunction();
                }
            },2*1000);
            return () => clearInterval(interval);
        },[]);

        useEffect(
            ()=>{
                const interval = setInterval(() => {

                    if(InitializationSequenceRef.current<4)
                    {
                        InitializeProject();
                    }
                    else
                    {
                        clearInterval(interval);
                    }

                    },10);
                return () => clearInterval(interval);
            },[]);
    
    

        useEffect
        (
            ()=>{
                console.log(ProjectsMemberships)
                
            },[ProjectsMemberships]
        )


    return(
        <Container>

        <Paper elevation={0} 
        sx={{
            
            p: 2,
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            alignContent: 'center',
            justifyContent: "space-between",
            minHeight: '75vh',
            backgroundColor: '#FAF9F6'
        }}>

            { ((IsStoryNotQuick) && (activeStep==0))&&
            <Box sx={{alignContent: 'center', display: 'flex', flexDirection: 'column', flexGrow:2, justifyContent:'center'}}>
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:5, opacity:0.8}}>Welcome to your new project.</Typography>
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:5, opacity:0.8}}>We will be guiding you along while you write the project's contract.</Typography>
            </Box>
            }
                        
            { (((IsStoryNotQuick) && (activeStep==1)) || (!IsStoryNotQuick)) &&
            <Box sx={{alignContent: 'center', alignItems:'center', display: 'flex', flexDirection: 'column', flexGrow:2, justifyContent:'center'}}>
                {IsStoryNotQuick&&
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:2, opacity:0.8}}>Let's start by giving your project a <strong>Title</strong> and a short <strong>Description</strong>.</Typography>
                }
                    <TextField
                        sx={
                        IsStoryNotQuick ?
                        {minWidth:'50%', m:2}:
                        {width:'100%', m:2}
                        }
                        id="outlined-helperText"
                        label="Project Title"
                        value={project?.projectTitle||''}
                        margin="none"
                        onChange={(e) => 
                            {setProject({...project, projectTitle:e.target.value, isDirty:true}); 
                            if(!IsCreateNotEdit){setDatabaseStatusRequest("Sync");}
                        }}
                    />

                    <br/>

                    <TextareaAutosize
                    aria-label="minimum height"
                    minRows={15}
                    placeholder="Description"
                    value={project?.description||''}
                    style={
                        IsStoryNotQuick ?
                        { width: '50%', margin:"normal"}:
                        {width:'100%', margin:2}
                        }
                    onChange={(e) => {
                        setProject({...project, description:e.target.value, isDirty:true}); 
                        if(!IsCreateNotEdit){setDatabaseStatusRequest("Sync");}
                    }}/>

            </Box>
            }


            { (((IsStoryNotQuick) && (activeStep==2)) || (!IsStoryNotQuick)) &&
            <Box sx={{alignContent: 'center', alignItems:'center', display: 'flex', flexDirection: 'column', flexGrow:2, justifyContent:'center'}}>

            {IsStoryNotQuick&&
            <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:5, opacity:0.8}}>Now please select the <strong>Business Category</strong> the project belongs to, this will help potential investors and contributers find your project, if you don't find the category you're looking for you can add your own.</Typography>
            }

                <Autocomplete 
                    style={
                        IsStoryNotQuick ?
                        { width: '50%'}:
                        {width:'100%', margin:2}
                        }

                    value={project?.category||''}
                    onChange={(event:any, newValue:any) => {
                        if (typeof newValue == 'string') {
                            setProject({...project, category:newValue, isDirty:true}); 
                            if(!IsCreateNotEdit){setDatabaseStatusRequest("Sync");}
                        } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                            let newCategory:CategoryType = {title:capitalizeFirstLetter(newValue.inputValue) }
                            setProject({...project, category:capitalizeFirstLetter(newValue.inputValue), isDirty:true});
                            setCategories([...categories, newCategory]);
                            setNewCategory(newCategory);
                            if(!IsCreateNotEdit){setDatabaseStatusRequest("Sync");}
                        } else {
                            setProject({...project, category:newValue.title, isDirty:true});
                            if(!IsCreateNotEdit){setDatabaseStatusRequest("Sync");}
                        }
                    }}
                    filterOptions={(options:any, params:any) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option:any) => inputValue == option.title);
                        if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            title: `Add a new Business Category "${capitalizeFirstLetter(inputValue)}"`,
                        });
                        }
                        return filtered;
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                    id="categories"
                    options={categories||[]}
                    getOptionLabel={(option:any) => {
                        // Value selected with enter, right from the input
                        if (typeof option == 'string') {
                        return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                        return option.inputValue;
                        }
                        // Regular option
                        return option.title;
                    }}
                    renderOption={(props, option:any) => <li {...props}>{option.title}</li>}
                    freeSolo
                    renderInput={(params) => (
                        <TextField sx={{width:'100%', my:2}} {...params} label="Business Category" />
                    )}                    
                    />
            </Box>
            }

            { (((IsStoryNotQuick) && (activeStep==3)) || (!IsStoryNotQuick)) &&
            <Box sx={{alignContent: 'center', alignItems:'center', display: 'flex', flexDirection: 'column', flexGrow:2, justifyContent:'center'}}>

            {IsStoryNotQuick&&
            <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:5, opacity:0.8}}>Please select the <strong>Skills</strong> you believe will be needed to implement the project, if you don't find what you're looking for you can add your own, and you can add to or modify them later.</Typography>
            }
                <Autocomplete
                    style={
                        IsStoryNotQuick ?
                        { width: '50%'}:
                        {width:'100%', margin:2}
                        }
                    multiple
                    onChange={(event:any, newValue:any[]) => {

                            newValue.forEach( (skillValue, index, array) => {
                                if(!skills.map(skill=>skill.title).includes(skillValue))
                                {
                                    array[index] = capitalizeFirstLetter(skillValue);
                                    let newSkill:SkillType = {title:capitalizeFirstLetter(skillValue) }
                                    setSkills([...skills, newSkill]);
                                    setnewSkills([...newSkills, newSkill]);
                                }
                            });
                            setProject({...project, skillList:newValue, isDirty:true});
                            if(!IsCreateNotEdit){setDatabaseStatusRequest("Sync");}
                        }
                    }
                    value = {project?.skillList||[]}
                    id="tags-filled"
                    options={skills?.map((skill) => skill.title)||[]}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                    }
                    renderInput={(params) => (
                    <TextField sx={{width:'100%'}}
                        {...params}
                        variant="filled"
                        label="Needed Skills"
                        placeholder=""
                    />
                    )}
                    />
            </Box>
            }

            { (((IsStoryNotQuick) && (activeStep==4)) || (!IsStoryNotQuick)) &&
            <Box sx={{alignContent: 'center', alignItems:'center', display: 'flex', flexDirection: 'column', flexGrow:2, justifyContent:'center'}}>

            {IsStoryNotQuick&&
            <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:5, opacity:0.8}}>Please identify your<strong> Team </strong> 
                of collaborators and define their roles, you can invite others later.</Typography>
            }

                <Autocomplete
                sx={
                    IsStoryNotQuick ?
                    {width:'50%', m:1, alignSelf:'center'}:
                    {width:'100%', m:2}
                    }

                disablePortal
                id="User-search"
                options={Users.filter(user=>(user.uid !== currentUserId))}
                getOptionLabel={(User) => User.name??""}
                renderOption={(props, User) => (
                    <Box key={User.uid} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <UserAvatar key={User.uid} userId={User.uid!} userName={User.name!} callContext="Messages" />
                        <Typography key={User.uid} sx={{ m:1 }} >{User.name}</Typography>
                    </Box>
                  )}
            
                onChange={(e:any)=>{
                    let targetUser:UserType = Users?.find(userItem => (userItem.name==e.target.textContent)) ?? {} as UserType;
                    if(!ProjectsMemberships?.find(membership=>(membership.userId==targetUser?.uid)))
                    {
                        let newMembershipItem:projectMembershipType = {userId: targetUser.uid, projectId:project.id, 
                            memberName: targetUser.name, status:"active", role:'Contributer', isDirty:true};
                        setProjectsMemberships([...ProjectsMemberships, newMembershipItem])
                    }
                }}
                renderInput={(params) => (
                <TextField {...params} label="Find your team." />
                )}
                />

                {ProjectsMemberships.length>1&&
                <List dense 
                sx={
                    IsStoryNotQuick ?
                    { width: '75%', bgcolor:'white', alignSelf:'center', m:1, borderRadius:5}:
                    { width: '100%', bgcolor:'white', alignSelf:'center', mt:1, mb:2, borderRadius:5}
                    }>
                    
                    <ListSubheader sx={{bgcolor:'white', opacity:0.8}}>Project Members</ListSubheader>
                    {ProjectsMemberships.map((membership) => { 
                        if (membership.userId!==currentUserId)

                        return (
                        <ListItem
                            disablePadding
                            >
                            <ListItemButton>
                                <ListItemAvatar>
                                    <UserAvatar userId={membership.userId!} userName={membership.memberName!} callContext="Messages" />
                                </ListItemAvatar>
                                <ListItemText  primary={membership.memberName} />
                            </ListItemButton>

                            <Tooltip title="Admin">
                            <Switch
                            //edge="end"
                            sx={{m:0.5}}
                            onChange={()=>{
                                setProjectsMemberships(
                                    ProjectsMemberships.map(membershipInner=>{
                                      if(membershipInner.userId==membership.userId && membershipInner.userId!==currentUserId)
                                      {
                                        if(membershipInner.role=="Admin")
                                        membershipInner.role = "Contributer";

                                        else if(membershipInner.role=="Contributer")
                                        membershipInner.role = "Admin";

                                        membership.isDirty = true;
                                        if(!IsCreateNotEdit){setDatabaseStatusRequest("Sync");}
                                    }
                                      console.log(membershipInner)
                                      console.log(membership)

                                      return membershipInner;
                                    }));
                            }}
                            checked={membership.role=="Admin"}
                            />
                            </Tooltip>

                            <IconButton 
                            sx={{m:0.5}}
                            onClick={(e)=>{
                                if(membership.userId!==currentUserId)
                                setProjectsMemberships(ProjectsMemberships.filter(innerMembership=>(innerMembership.userId!==membership.userId)));
                                }} edge="end" aria-label="delete">
                                <DeleteIcon />
                            </IconButton>

                        </ListItem>
                        );
                    })}
                    </List>
                    }

                {IsStoryNotQuick&&
                <Typography sx={{fontSize:'min(2vw,17px)', mx:'10vw', my:1, opacity:0.8}}>Admins have the right to 
                add or remove members, create tasks and assign compensations, you are an admin by default.</Typography>
                }
            </Box>
            }

            { (((IsStoryNotQuick) && (activeStep==5)) || (!IsStoryNotQuick)) &&

            <Box sx={{alignContent: 'center', alignItems:'center', display: 'flex', flexDirection: 'column', flexGrow:2, justifyContent:'center'}}>

                {IsStoryNotQuick&&
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:5, opacity:0.8}}>Please select the Currency that will be used to compensate project members and exchange ownership shares.</Typography>
                }
                <ToggleButtonGroup
                sx={{m:2}}
                    color="primary"
                    value={project.currency}
                    exclusive
                    onChange={(e, value) => {
                        if(value!==null)
                        {
                            setProject({...project, currency:value, isDirty:true});
                            if(!IsCreateNotEdit){setDatabaseStatusRequest("Sync");}
                        }
                    }}
                    >
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="USD">USD</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="EUR">EUR</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="JPY">JPY</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="GBP">GBP</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="CNY">CNY</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="INR">INR</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="EGP">EGP</ToggleButton>
                        <ToggleButton sx={{fontSize:'min(3.5vw,13px)'}} value="BTC">BTC</ToggleButton>

                    </ToggleButtonGroup>
            </Box>
            }

            { (((IsStoryNotQuick) && (activeStep==6)) || (!IsStoryNotQuick)) &&
            <Box sx={{alignContent: 'center', alignItems:'center', display: 'flex', flexDirection: 'column', flexGrow:2, justifyContent:'center'}}>

                {IsStoryNotQuick&&
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:3, opacity:0.8}}>Let us define the initial number of shares
                that constitutes the project equity, this could be categorized into <strong>Authorized</strong> Shares,  
                 <strong> Outstanding</strong> Shares and <strong>Reserved</strong> Shares.</Typography>
                }
                 
                 {IsStoryNotQuick&&
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:3, opacity:0.8}}><strong>Authorized</strong> shares are the total target
                number of shares that are expected by the end of the project development, not all of them are allocated at the current point 
                in time, the ones that are allocated are called <strong>Outstanding</strong>, the ones that are not are called <strong>Reserved</strong>, 
                the idea of equity based compensation is to continouosly distribute reserved shares to project members according to their contribution turning 
                those reserved shares into outstanding ones, let us set the initial number of authorized 
                shares to<TextField type="number" inputProps={{ style: { textAlign: 'center' }}} size="small" value={project.authorizedShares} id="standard-basic" variant="standard" 
                sx={{position:"inherit", width:'100px', mx:0.5}} onChange={(value)=>{setProject({...project, authorizedShares:parseInt(value.target.value)} ); ResetTargetEquities(true);}} /> 
                Shares, a company could increase its authorized shares at any point in time "share issuance", this is an important source of financing but 
                it decreases the percentage ownership of every outstanding share so it should be only done after careful consideration to avoid demoralizing existing shareholders.</Typography> 
                }


                {IsStoryNotQuick&&
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:3, opacity:0.8}}>Now we want to define the project 
                <strong> Equity Components</strong>, these represent the main factors and activities necessary for the 
                project to succeed, consequently they also define how equity is to be divided, we will also identify whether every component is to be 
                rewarded with outstanding or reserved shares, compoonents that take effect in a single point in time like cash, a core idea or an 
                intellectual property should be rewarded with outstanding shares, while components that take effect over time like management, development, 
                or a technical skill should be rewarded with reserved shares, examples of equity components are defined, you may modify or add to them.</Typography>
                }

                {IsStoryNotQuick&&
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:3, mb:7, opacity:0.8}}>We also need to define the initial valuation of the project, this represents the money 
                    value you assign to the project as it starts, at this point it mainly affects the amount of money required to acquire the <strong>Cash</strong> equity components, let us set that
                    to <TextField type="number" inputProps={{ style: { textAlign: 'center' }}} size="small" value={project.currentValuation} id="standard-basic" variant="standard" 
                sx={{position:"inherit", width:'100px', mx:0.5}} onChange={(value)=>{setProject({...project, currentValuation:parseInt(value.target.value)} ); ResetTargetEquities(true);}} /> {project.currency}
                , valuation will be updated whenever shares of the project are traded in the future.
                </Typography>
                }


                {!IsStoryNotQuick&&
                 <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:4, opacity:0.8}}>The Project has 
                 <TextField type="number" inputProps={{ style: { textAlign: 'center' }}} size="small" value={project.authorizedShares} id="standard-basic" variant="standard" 
                 sx={{position:"inherit", width:'100px', mx:0.5}} onChange={(value)=>{setProject({...project, authorizedShares:parseInt(value.target.value)} ); ResetTargetEquities(true);}} />
                authorized shares, and its initial valuation is <TextField type="number" inputProps={{ style: { textAlign: 'center' }}} size="small" value={project.currentValuation} id="standard-basic" variant="standard" 
                sx={{position:"inherit", width:'100px', mx:0.5}} onChange={(value)=>{setProject({...project, currentValuation:parseInt(value.target.value)} ); ResetTargetEquities(true);}} /> {project.currency}
                </Typography>
                }

                <Typography variant="caption" sx={{width:'50%', textAlign:"center"}}>Click on a component to toggle it between Outstanding, Reserved and Cash.</Typography>
                <Autocomplete
                    multiple
                    sx={{minWidth:'50%', m:1, alignSelf:'center'}}

                    onChange={(event:any, updatedList:any[], reason, details) => {
                            console.log(updatedList)
                            console.log(reason)
                            console.log(details)

                            if(reason == "createOption")
                            {
                                setProject({...project, equityComponentList:
                                    [...(project.equityComponentList!) , ({name:details?.option, locked:false, type:"outstanding", focus:false} as EquityComponentType) ], isDirty:true});    
                            }
                            else if(reason == "removeOption")
                            {
                                setProject({...project, equityComponentList:
                                    project.equityComponentList!.filter(comp=>(comp.name !== details?.option))});
                            }
                            
                            ResetTargetEquities(true);
                            if(!IsCreateNotEdit){setDatabaseStatusRequest("Sync");}
                        
                    }}
                    value = {project?.equityComponentList?.map(comp=>comp.name)||[]}
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip variant="outlined" label={option} 
                        sx={{
                        color:(project.equityComponentList?.find(comp=>(comp.name==option))?.type=="cash")?'green':
                        (project.equityComponentList?.find(comp=>(comp.name==option))?.type=="outstanding")?'darkred':'blue',
                        borderColor:(project.equityComponentList?.find(comp=>(comp.name==option))?.type=="cash")?'green':
                        (project.equityComponentList?.find(comp=>(comp.name==option))?.type=="outstanding")?'darkred':'blue',
                        borderWidth:(project.equityComponentList?.find(comp=>(comp.name==option))?.focus==true)? 2:1,
                        fontWeight:(project.equityComponentList?.find(comp=>(comp.name==option))?.focus==true)? "bolder":"auto",
                    }}
                        onClick={(e:any)=>{
                            setProject({...project, equityComponentList:project.equityComponentList?.map((comp, idx, list)=>{
                                if(comp.name==e.target.textContent)
                                {
                                    if(comp.focus == true)
                                        {
                                            if(comp.type=="outstanding")
                                                {
                                                    comp.type = "reserved";
                                                }
                                                else if(comp.type=="reserved")
                                                {
                                                    comp.type = "cash";
                                                }
                                                else if(comp.type=="cash")
                                                {
                                                    comp.type = "outstanding";
                                                }
                                        }
                                    comp.focus = true;
                                }
                                else
                                {
                                    comp.focus = false;
                                }
                                return comp;
                            }), isDirty:true});
                            if(!IsCreateNotEdit){setDatabaseStatusRequest("Sync");}
                                                                        
                        }} 
                        
                          {...getTagProps({ index })} />
                    ))
                    }
                    renderInput={(params) => (
                    <TextField sx={{width:'100%'}}
                        {...params}
                        variant="filled"
                        label="Equity Components"
                        placeholder=""
                    />
                    )}
                    />
                    <Box width='25%' alignSelf={"center"} sx={{display:"flex", flexDirection:'row', mt:1, justifyContent:'space-evenly'}}>
                        <Chip variant="outlined" label={"Outstanding"} sx={{color:'darkred', borderColor:'darkred'}} />
                        <Chip variant="outlined" label={"Reserved"} sx={{color:'blue', borderColor:'blue'}} />
                        <Chip variant="outlined" label={"Cash"} sx={{color:'green', borderColor:'green'}} />
                    </Box>

                {project.equityComponentList?.length &&
                <TargetEquityChart equityComps={project.equityComponentList!} 
                setEquityCompFocus = {setEquityCompFocus}
                authorizedShares={project.authorizedShares!}
                IncreaseEquityCallback={increaseEquity} 
                ToggleLockCallback={toggleLockEquityComponent}
                ResetEquitiesCallback={() => ResetTargetEquities(true)}
                StepTooLargeError={StepTooLargeErrorState}/>
                }
            </Box>
            } 


            { (((IsStoryNotQuick) && (activeStep==7)) || (!IsStoryNotQuick)) && (IsCreateNotEdit) &&
            <Box sx={{alignContent: 'center', alignItems:'center', display: 'flex', flexDirection: 'column', flexGrow:2, justifyContent:'center'}}>

                {IsStoryNotQuick&&
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:3, opacity:0.8}}>Outstanding shares will be allocated to owners right at the beginning, so we need to identify
                their assignment to team members, for example if multiple members contribute to IP we need to identify how that component would be split amongst them. </Typography>
                }
                
                {project.equityComponentList?.length &&
                <InitEquityAssignmentChart equityComps={project.equityComponentList??([] as EquityComponentType[])} 
                projectMemberships={ProjectsMemberships} Transactions={TransactionsRef.current} setTransactions={setTransactions}
                project = {project!}/>
                }
            </Box>
            } 


            { (((IsStoryNotQuick) && (activeStep==8)) || (!IsStoryNotQuick)) &&
            <Box sx={{alignContent: 'center', alignItems:'center', display: 'flex', flexDirection: 'column', flexGrow:2, justifyContent:'center'}}>

                {IsStoryNotQuick&&
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', my:5, opacity:0.8}}>Would you like the project 
                to be private? a private project is not visible to the public and can only be joined by invitation, keep 
                it at public if you would like to allow qualified contributors to request membership.</Typography>
                }

                <FormGroup>
                        <Box sx={{ alignItems: 'center', m:2}}>
                        <FormControlLabel control={
                        <Switch  
                        checked={((project?.visibility=="private")? true:false)}
                        onChange={handleSetVisibility}
                        size="medium"
                        />} label={((project?.visibility=="private")? "Private Project":"Public Project")} />
                        </Box>
                </FormGroup>
            </Box>
            } 

            { (((IsStoryNotQuick) && (activeStep==9)) || (!IsStoryNotQuick)) && (IsCreateNotEdit) &&
            <Box sx={{alignContent: 'center', alignItems:'center', display: 'flex', flexDirection: 'column', flexGrow:2, justifyContent:'center'}}>


                {((project.projectTitle?.length??0) == 0) &&
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', opacity:0.8, color:'orange'}}>Project title is missing</Typography>
                }
                {((project.description?.length??0) == 0) &&
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', opacity:0.8, color:'orange'}}>Project description is missing</Typography>
                }
                {((project.category?.length??0) == 0) &&
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', opacity:0.8, color:'orange'}}>Business category is not selected</Typography>
                }
                {((project.skillList?.length??0) == 0) &&
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', opacity:0.8, color:'orange'}}>No skills are selected</Typography>
                }
                {((ProjectsMemberships.length??1) == 1) &&
                <Typography sx={{fontSize:'min(3.5vw,20px)', mx:'5vw', opacity:0.8, color:'orange'}}>No team members were added</Typography>
                }

                <Button type="submit" sx={{textTransform: 'none', m: 3, width:'15%', alignSelf:'center'}} variant="contained" onClick={saveDBCreate}>Submit Project</Button>

            </Box>
            } 

            {IsStoryNotQuick&&
            <Box sx={{alignContent: 'center', justifySelf:'flex-end', display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
            <MobileStepper
            variant="dots"
            steps={10}
            position="static"
            activeStep={activeStep}
            sx={{backgroundColor: 'transparent'  }}
            nextButton={
                <Button sx={{fontSize:'min(2.5vw,15px)'}} size="small" onClick={handleNext} disabled={activeStep == 9}>
                Next
                {theme.direction == 'rtl' ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
                </Button>
            }
            backButton={
                <Button sx={{fontSize:'min(2.5vw,15px)'}} size="small" onClick={handleBack} disabled={activeStep == 0}>
                {theme.direction == 'rtl' ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
                Back
                </Button>
                }
            />
            </Box>
            }
        </Paper>
        </Container>
    );

}
