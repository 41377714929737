import { Box, Button, Fade, MobileStepper, Typography } from "@mui/material";
import { useEffect } from "react";
import useState from 'react-usestateref';

export default function LandingPagePanel({activeStepRequest}:{activeStepRequest:number}) {

    const [activeStepState, setactiveStepState, activeStepRef] = useState(0);

    const missionStatements = ["Start working on your idea with minimal initial funding", 
        "Manitain a fair and dynamic equity distribution structure", 
        "Create an environment of friendly competition", 
        "Reduce the risk of co-founder conflict"]

    const STEP_COUNT = 4;

    const [scrollPosition, setScrollPosition, scrollPositionRef] = useState(0);
    const [render, setRender] = useState(false);

    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };
  
    useEffect(() => {

        setRender(false);

        setTimeout(() => {
            setactiveStepState(activeStepRequest);
        }, 300);
        
      }, [activeStepRequest]);

      useEffect(() => {

        setTimeout(() => {
            setRender(true);
        }, 300);
        
      }, [activeStepState]);
      
      useEffect(
        ()=>{
  
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
        },[]);
  
    return(

    <Fade 
    in={render}
    style={{ transformOrigin: 'top' }}
    {...({ timeout: 300 })}
    >   
        <Box sx={{ 
        mx:5,  alignSelf:'center',
        backgroundImage:`url(${require('../Image/ScreenShot'.concat((activeStepState+1).toString()).concat(".jpg"))})`,
        //backgroundImage:`url(${require('../Image/ScreenShot1.jpg')})`,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        //backgroundPositionY:(scrollPosition/4),
        borderRadius:3,
        width:'98vw', 
        height:'50vw',
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-end'
        }}>
        <Typography color={'white'} align={'left'} fontSize={'min(8vw,90px)'} fontFamily={'fantasy'} 
        sx={{mx:2, textShadow:'1px 1px 1px', WebkitTextStroke:'2px gray'}} 
        >{missionStatements[activeStepState]}</Typography>

        <MobileStepper
        variant="dots"
        steps={STEP_COUNT}
        position="static"
        activeStep={activeStepState}
        sx={{backgroundColor: 'transparent', alignSelf:'center', }}
        nextButton={<Button />}
        backButton={<Button />}
        />            
        </Box>
    </Fade >
)
}