import { ResponsiveNetwork } from '@nivo/network'
import { projectMembershipType, ProjectType, TransactionType } from './Interfaces';
import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Box, Paper } from '@mui/material';


export default function AdminView ()
{

    const [Projects, setProjects] = useState<ProjectType[]>([]);
    const [ProjectsMemberships, setProjectsMemberships] = useState<projectMembershipType[]>([]);
    const [Transactions, setTransactions] = useState<TransactionType[]>([]);

    const [data, setData] = useState<{
        nodes: {
            id: string;
            height: number;
            size: number;
            borderWidth: number;
            color: string;
        }[];
        links: {
            source: string;
            target: string;
            distance: number;
            type?: string;
        }[];}>();

    function loadDB()
    {
        const fetchProjects = async () => {
        const querySnapshot = await getDocs(collection(db, "Projects"));
        let Projects:ProjectType[]=[];
        querySnapshot.docs.forEach(doc=>{
            let Project:ProjectType;
            Project=doc.data();
            Project.id = doc.id;
            Project.isDirty = false;
            Projects.push(Project);
        })
        setProjects(Projects)
        }
        fetchProjects().catch((e)=>{});   

        const fetchProjectsMemberships = async () => {
            const querySnapshot = await getDocs(collection(db, "ProjectsMemberships"));
              let projectsMemberships:projectMembershipType[]=[];
              querySnapshot.docs.forEach(doc=>{
                let projectMembership:projectMembershipType;
                projectMembership=doc.data();
                projectMembership.id = doc.id;
                projectsMemberships.push(projectMembership);
              })
              setProjectsMemberships(projectsMemberships)
            }
        fetchProjectsMemberships().catch((e)=>{});  

        const fetchTransactions = async () => {
            const querySnapshot = await getDocs(collection(db, "Transactions"));
            let Transactions:TransactionType[]=[];
            querySnapshot.docs.forEach(doc=>{
                let Transaction:TransactionType;
                Transaction=doc.data();
                Transaction.id = doc.id;
                Transaction.isDirty = false;
                Transactions.push(Transaction);
            })
            setTransactions(Transactions)
            }
            fetchTransactions().catch((e)=>{});   
    
    }

    useEffect(
        ()=>{
            loadDB();
        },[]);


    return(
    <Paper 
    
    sx={{height:1200}}>

        <ResponsiveNetwork
            data={{
                nodes: 
                Projects.map(project=>({
                    "id": "Project: " + project.projectTitle??"",
                    "height": 1,
                    "size": 50,
                    "color": "rgb(97, 205, 187)"}))

                    .concat(Projects.map(project=>{
                        let equityComps:{
                            id: string;
                            height: number;
                            size: number;
                            color: string;
                        }[] = [];
                        project.equityComponentList?.forEach(element => {
                            equityComps.push(
                            {
                                "id": "Equity Comp: " + element.name + " : " + project.projectTitle,
                                "height": 1,
                                "size": 25,
                                "color": "rgb(157, 155, 187)"            
                            })
                        });
                        return equityComps;
                    }).flat())

                    .concat(ProjectsMemberships.map(membership=>({
                        "id": "Membership: " + membership.memberName + " : " + membership.id?.substring(0,5),
                        "height": 1,
                        "size": 25,
                        "color": "rgb(197, 105, 287)"    
                    })))
                    
                    .concat(Transactions.map(Transaction=>({
                        "id": "Transaction: " + Transaction.type +
                        " : " + Transaction.sourceName+ 
                        " : " + Transaction.destinationName +
                        " : " + Transaction.equityComponent +
                        " : " + Transaction.id?.substring(0,5)  ,
                        "height": 1,
                        "size": 12,
                        "color": Transaction.type=="equity" ? "rgb(7, 105, 287)" : "rgb(7, 105, 87)"    
                    })))
                    
                ,
                links:
                
                ProjectsMemberships.map(membership=>{
                    let projectlinks:{
                        source: string;
                        target: string;
                        distance: number;
                        type?: string;
                    }[] = [];
                    Projects.forEach(project=>{
                        if(project.id == membership.projectId)
                        {
                            projectlinks.push({
                                "source": "Project: " + project.projectTitle??"",
                                "target": "Membership: " + membership.memberName + " : " + membership.id?.substring(0,5),
                                "distance": 150
                            });
                        }
                    })
                    return projectlinks;
                }).flat()

                .concat(Projects.map(project=>{
                    let equityCompslinks:{
                        source: string;
                        target: string;
                        distance: number;
                        type?: string;
                    }[] = [];

                    project.equityComponentList?.forEach(element => {
                        equityCompslinks.push(
                        {
                            "source": "Project: " + project.projectTitle??"",
                            "target": "Equity Comp: " + element.name + " : " + project.projectTitle,
                            "distance": 100
                    })
                    });
                    return equityCompslinks;
                }).flat())

                /*.concat(Transactions.map(Transaction=>{
                    let Transactionlinks:{
                        source: string;
                        target: string;
                        distance: number;
                        type?: string;
                    }[] = [];
                    Projects.forEach(project=>{
                        if( (project.id == Transaction.destinationId && Transaction.destinationType=="project") ||
                        (project.id == Transaction.sourceId && Transaction.sourceType=="project")
                        )
                        {
                            Transactionlinks.push({
                                "source": "Project: " + project.projectTitle??"",
                                "target": "Transaction: " + Transaction.type +
                                            " : " + Transaction.sourceName+ 
                                            " : " + Transaction.destinationName +
                                            " : " + Transaction.id?.substring(0,5),
                                "distance": 200
                            });
                        }
                    })
                    return Transactionlinks;
                }).flat())*/

                .concat(ProjectsMemberships.map(membership=>{
                    let Transactionlinks:{
                        source: string;
                        target: string;
                        distance: number;
                        type?: string;
                    }[] = [];
                    Transactions.forEach(Transaction=>{
                        if( 
                        (membership.userId == Transaction.destinationId && Transaction.destinationType=="user" 
                            && Transaction.sourceType=="project" && Transaction.sourceId==membership.projectId) ||
                        (membership.userId == Transaction.sourceId && Transaction.sourceType=="user"
                            && Transaction.destinationType=="project" && Transaction.destinationId==membership.projectId)
                        )
                        {
                            Transactionlinks.push({
                                "source": "Membership: " + membership.memberName + " : " + membership.id?.substring(0,5),
                                "target": "Transaction: " + Transaction.type +
                                            " : " + Transaction.sourceName+ 
                                            " : " + Transaction.destinationName +
                                            " : " + Transaction.equityComponent +
                                            " : " + Transaction.id?.substring(0,5),
                                "distance": 50
                            });
                        }
                    })
                    return Transactionlinks;
                }).flat())

            }}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            linkDistance={e=>e.distance}
            centeringStrength={1}
            repulsivity={10}
            iterations={260}
            nodeSize={n=>n.size}
            activeNodeSize={n=>1.5*n.size}
            nodeColor={e=>e.color}
            nodeBorderWidth={1}
            nodeBorderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.8
                    ]
                ]
            }}
            linkThickness={n=>2+2*n.target.data.height}
            linkBlendMode="multiply"
            motionConfig="wobbly"
        />
    </Paper>
    )
}
