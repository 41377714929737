import { Box, Paper, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ResponsiveSunburst } from '@nivo/sunburst'
import { projectMembershipType, TransactionType } from "./Interfaces";
import Title from "./Title";
import { useEffect, useState } from "react";


export default function EquityChart({ProjectsMemberships, Transactions}:
    {ProjectsMemberships:projectMembershipType[], Transactions:TransactionType[]}) {

    const [SharesPercentages, setSharesPercentages] = useState<"Shares"|"Percentages">("Shares");


    useEffect(
        ()=>{
            console.log(Transactions);
        },[Transactions]);

    return (
    <Paper elevation={0} 
    sx={{
        py: 2,
        my: 2,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5,
        //height:350
    }}>

        <Title >Equity Distribution</Title>

        <ToggleButtonGroup
            sx={{alignSelf:'center', m:2, mb:5}}
            size="small"
            value={SharesPercentages}
            onChange={(event, value)=>{
                if(value!==null)
                {setSharesPercentages(value)}
            }}
            aria-label="text formatting"
            exclusive
            orientation= {"horizontal"}
            >
                <ToggleButton sx={{fontSize:'min(2.8vw,15px)', textTransform: 'none'}} value="Shares" >Shares</ToggleButton>
                <ToggleButton sx={{fontSize:'min(2.8vw,15px)', textTransform: 'none'}} value="Percentages">%</ToggleButton>
        </ToggleButtonGroup>

            
        <Box sx={{height:600}}>
            <ResponsiveSunburst
                data={{
                        name: "Equity",
                        children:
                            ProjectsMemberships.map(membership=>({
                                name: membership.memberName,
                                children:
                                Transactions
                                .filter(transaction=>transaction.destinationId==membership.userId)
                                .filter(transaction=>transaction.type=="equity").map(transaction=>
                                ({
                                    name: membership.memberName+": "+transaction.equityComponent,
                                    loc: transaction.value
                                })
                                )
                            }))    
                    }}

                arcLabel={e=> SharesPercentages=="Shares" ? (e.id+" ("+e.value+")"): (e.id+" ("+e.percentage.toFixed(2)+" %)")}
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                id="name"
                value="loc"
                cornerRadius={10}
                borderWidth={0}
                borderColor={{ theme: 'background' }}
                colors={{ scheme: 'tableau10' }}
                childColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'opacity',
                            0.6
                        ]
                    ]
                }}
                enableArcLabels={true}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor="black"
                />
        </Box>
    </Paper>    
);
}