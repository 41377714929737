import { Box, Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ResponsiveLine  } from "@nivo/line";
import Title from "./Title";
import { EquityOrderType, TransactionType } from "./Interfaces";
import { useEffect, useState } from "react";
import React from "react";


export default function ValuationChart({Transactions, Orders}:{Transactions:TransactionType[], Orders:EquityOrderType[]}) {

    const [Selector, setSelector] = useState<"Valuation"|"SharePrice"|"OutstandingShares"|"Orders">("Valuation");
    const [Data, setData] = useState(
        [
            {"id": "Valuation" ,

            "data": 
                Transactions.filter(transaction=>(transaction.type=="cash"))
                .sort((a,b)=>{return (Date.parse(a.date!)-Date.parse(b.date!))})
                .map(transaction=>{

                    let outstandingShares = Transactions
                    .filter(transaction=>(transaction.type=="equity"))
                    .filter(transaction=>(transaction.marketType=="primary"))
                    .filter(innerTransaction=> (Date.parse(transaction.date!) >= Date.parse(innerTransaction.date!)))
                    .reduce((sum:number, value)=>{sum =sum+(value.value??0); return sum;}, 0);

                    return {
                        "x": transaction.date,
                        "y": (Selector=="Valuation") ? transaction.valuation??0 : 
                        (Selector=="SharePrice") ? ((transaction.valuation??0)/outstandingShares).toFixed(2) : 
                        outstandingShares
                    }
                }) }
            ]
    );

    React.useEffect(
        ()=>{
            console.log(Orders);

            console.log(Data);
    },[Data]);


    React.useEffect(
        ()=>{
          
        if(Selector=="Valuation")
        {
            setData(
                [
                    {"id": "Valuation" ,
        
                    "data": 
                        Transactions.filter(transaction=>(transaction.type=="cash"))
                        .sort((a,b)=>{return (Date.parse(a.date!)-Date.parse(b.date!))})
                        .map(transaction=>{        
                            return {
                                "x": transaction.date,
                                "y": transaction.valuation??0 
                            }
                        }) }
                    ]
        
            );
        }
        else if(Selector=="SharePrice")
        {
            setData(
                [
                {"id": "SharePrice" ,
    
                "data": 

                Transactions.filter(transaction=>(transaction.type=="cash"))
                .sort((a,b)=>{return (Date.parse(a.date!)-Date.parse(b.date!))})
                .map(transaction=>{

                    let outstandingShares = Transactions
                    .filter(transaction=>(transaction.type=="equity"))
                    .filter(transaction=>(transaction.marketType=="primary"))
                    .filter(innerTransaction=> (Date.parse(transaction.date!) >= Date.parse(innerTransaction.date!)))
                    .reduce((sum:number, value)=>{sum =sum+(value.value??0); return sum;}, 0);

                    return {
                        "x": transaction.date,
                        "y":  ((transaction.valuation??0)/outstandingShares).toFixed(2) 
                    }
                })}]
            );
        }
        else if(Selector=="OutstandingShares")
        {
            setData(
                [
                    {"id": "OutstandingShares" ,
        
                    "data": 
    
                    Transactions.filter(transaction=>(transaction.type=="cash"))
                    .sort((a,b)=>{return (Date.parse(a.date!)-Date.parse(b.date!))})
                    .map(transaction=>{
    
                        let outstandingShares = Transactions
                        .filter(transaction=>(transaction.type=="equity"))
                        .filter(transaction=>(transaction.marketType=="primary"))
                        .filter(innerTransaction=> (Date.parse(transaction.date!) >= Date.parse(innerTransaction.date!)))
                        .reduce((sum:number, value)=>{sum =sum+(value.value??0); return sum;}, 0);
    
                        return {
                            "x": transaction.date,
                            "y":  outstandingShares
                        }
                    })}]
    
            );
            
        }
        else if(Selector=="Orders")
        {
            setData(
                [
                    {
                        "id": "Buy Orders" ,
                        "data":
                        Orders
                        .sort((a,b)=>{return (Date.parse(a.date!)-Date.parse(b.date!))})
                        .filter(order=>order.status=="Active")
                        .map(order=>
                        {
                            if(order.type=="Buy")
                            {
                                return {
                                    "x": order.date!,
                                    "y": order.price!
                                }    
                            }
                            else
                            {
                                return {
                                    "x": order.date!,
                                    "y": null!
                                }    
                            }
                        }
                        )
        
                    }

                    ,{
                        "id": "Sell Orders" ,
                        "data":
                        Orders
                        .sort((a,b)=>{return (Date.parse(a.date!)-Date.parse(b.date!))})
                        .filter(order=>order.status=="Active")
                        .map(order=>
                        {
                            if(order.type=="Sell")
                                {
                                    return {
                                        "x": order.date!,
                                        "y": order.price!
                                    }    
                                }
                                else
                                {
                                    return {
                                        "x": order.date!,
                                        "y": null!
                                    }    
                                }
                            }
                        )        
                    }
                ]
            );            
        }
    
    },[Selector]);


    return (

      <Paper elevation={0} 
      sx={{
          py: 2,
          my: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems:'center',
          borderRadius: 5,
      }}>
        <Title >Valuation History</Title>

        <ToggleButtonGroup
            sx={{alignSelf:'center', m:2, mb:5}}
            size="small"
            value={Selector}
            onChange={(event, value)=>{
                if(value!==null)
                {setSelector(value)}
            }}
            aria-label="text formatting"
            exclusive
            orientation= {"horizontal"}
            >
                <ToggleButton sx={{fontSize:'min(2.8vw,15px)', textTransform: 'none'}} value="Valuation" >Valuation</ToggleButton>
                <ToggleButton sx={{fontSize:'min(2.8vw,15px)', textTransform: 'none'}} value="SharePrice">Share Price</ToggleButton>
                <ToggleButton sx={{fontSize:'min(2.8vw,15px)', textTransform: 'none'}} value="OutstandingShares">Outstanding Shares</ToggleButton>
                <ToggleButton sx={{fontSize:'min(2.8vw,15px)', textTransform: 'none'}} value="Orders">Orders</ToggleButton>
        </ToggleButtonGroup>


        <Box sx={{height:350, width:'70vw'}}>
            <ResponsiveLine
            data =  {Data}

            margin={{ top: 50, right: 110, bottom: 50, left: 110 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: false,
                reverse: false
            }}
            curve="linear"
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                //orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Time',
                legendOffset: 36,
                legendPosition: 'middle'
            }}
            axisLeft={{
                //orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend:  (Selector=="Valuation") ? "Valuation":
                (Selector=="SharePrice") ? "Share Price":
                (Selector=="OutstandingShares") ? "Outstanding Shares":
                "Orders",
                legendOffset: -80,
                legendPosition: 'middle'
            }}
            colors={{ scheme: 'set2' }}
            pointSize={10}
            lineWidth={(Selector!="Orders") ? 6 : 0}
            enableArea={(Selector!="Orders") ? true: false}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
      </Box>
    </Paper>
    );
}