import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { getRowIdFromRowModel } from '@mui/x-data-grid/internals';
import { Box, Button, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Link } from 'react-router-dom';
import { EquityOrderType, ErrorLogType, ReviewType, TransactionType } from './Interfaces';
import useWindowDimensions from './useWindowDimensions';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import useState from 'react-usestateref';
import { addDoc, collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import Project from './Project';



export default function OrderTable({
  EquityOrders, 
  currency, 
  OutstandingShares,
  setDatabaseStatusRequest, 
  setDatabaseErrorDesc, 
  loadDBFn}:
  {EquityOrders:EquityOrderType[], 
    currency:string, 
    OutstandingShares:number, 
    setDatabaseStatusRequest:Function, 
    setDatabaseErrorDesc:Function, 
    loadDBFn:Function}) {

    const [orderType, setorderType] = useState<string>("Buy");
    const [RatingsMaps, setRatingsMaps] = useState<Map<string,number>[]>([]);


    const [user, loading, error] = useAuthState(auth);
    let currentUserId = user?.uid;
    let currentUserName = user?.displayName??"";

    const [EquityOrdersState, setEquityOrdersState, EquityOrdersStateRef] = useState<EquityOrderType[]>([]);
    const [Transactions, setTransactions, TransactionsRef] = useState<TransactionType[]>([]);

    React.useEffect(
      ()=>{
        setEquityOrdersState(EquityOrders);
  },[EquityOrders]);


  function AverageRating(Reviews:ReviewType[])
  {
    return Reviews.reduce((sum:number, value)=>{sum =sum+(value.Rating??0); return sum;}, 0)/(Reviews.length!==0 ? Reviews.length:1)*100/5;
  }

  React.useEffect(
    ()=>{
        //loadDB();
        const interval = setInterval(() => {
            saveDBEdit();
        },2*1000);
        return () => clearInterval(interval);
    },[]);

    function getOwnersRating(ownerId:string){

        const fetchReviews = async (ownerId:string) => {

          const q = query(collection(db, "Reviews"), where("targetId", "==", ownerId));
          const querySnapshot = await getDocs(q);
              let Reviews:ReviewType[]=[];
              querySnapshot.docs.forEach(doc=>{
              let Review:ReviewType;
              Review=doc.data() as ReviewType;
              Review.id = doc.id;
              Review.isDirty = false;
              Reviews.push(Review);
              })
              setRatingsMaps([...RatingsMaps, new Map([[ownerId, AverageRating(Reviews)]])] );
              return AverageRating(Reviews);
          }

          if(RatingsMaps?.find(map=>map.has(ownerId))==undefined )
          {
            fetchReviews(ownerId).catch((e)=>{ErrorHandler(e, "Fetch Reviews")})
            .then(()=>{setDatabaseStatusRequest("OK"); }).then((rating)=>{return rating});  
          }
          else
          {
            //console.log(RatingsMaps?.find(map=>map.has(ownerId))?.get(ownerId));
            return RatingsMaps?.find(map=>map.has(ownerId))?.get(ownerId);
          }
     }
    
    async function saveDBEdit()
    {
      let activeCycle=false;
      EquityOrdersStateRef.current?.forEach(async (order,i)=>{
          if(order.isDirty==true)
          {   
            activeCycle=true;
            await setDoc(doc(db, "EquityOrders", order.id??""), order).then(()=>{
              setEquityOrdersState(EquityOrdersStateRef.current?.map((order,j)=>{if(i==j){order.isDirty=false; }return order;})
            )}).catch((e)=>{ErrorHandler(e, "Edit EquityOrder");}).then(()=>{setDatabaseStatusRequest("OK");});
          }
      })

      TransactionsRef.current.forEach(async (transaction, i)=>{
        if(transaction.isDirty==true)
        {
          activeCycle=true;
          await addDoc(collection(db, "Transactions"), transaction ).then(()=>{
            setTransactions(TransactionsRef.current?.map((transaction,j)=>{if(i==j){transaction.isDirty=false; }return transaction;})
            )}).catch((e)=>{ErrorHandler(e, "Submit Transactions");}).then(()=>{setDatabaseStatusRequest("OK");});
        }
    })

    if(activeCycle==false)
    {
        if(EquityOrdersStateRef?.current?.some(order=>order.isDirty==true))
        {
          setDatabaseStatusRequest("DatabaseError");              
        }
        if(TransactionsRef?.current?.some(Transaction=>Transaction.isDirty==true))
        {
          setDatabaseStatusRequest("DatabaseError");              
        }
      }
      
  }
  
  async function ErrorHandler(error:Error, SourceFunction:string)
  {
    let errorLog:ErrorLogType = {file: "OrderTable", message: error.message, userId:currentUserId??"", date:new Date(), sourceFunction: SourceFunction, }
    setDatabaseStatusRequest("DatabaseError");
    setDatabaseErrorDesc(SourceFunction);
  }

  function CreateBuyTransaction (order: EquityOrderType)
  {

    let transactionsBuffer: TransactionType[] = [];
    let transaction:TransactionType;

    transaction = 
    {
        date:new Date().toUTCString(), 
        projectId: order.projectId!,
        marketType:'secondary',
        type:'equity',

        sourceType:'user',
        sourceId: order.CounterpartId,
        sourceName: order.Counterpartname,

        destinationType:'user',
        destinationId: order.OwnerId,
        destinationName: order.Ownername,

        //equityComponent: ,
        status:'Confirmed',
        value:order.shares,
        valuation: OutstandingShares * (order.price??0),
        locked:false,
        isDirty:true
    }
    transactionsBuffer.push(transaction);

    transaction = 
    {
      date:new Date().toUTCString(), 
      projectId: order.projectId!,
      marketType:'secondary',
      type:'cash',

      sourceType:'user',
      sourceId: order.OwnerId,
      sourceName: order.Ownername,

      destinationType:'user',
      destinationId: order.CounterpartId,
      destinationName: order.Counterpartname,

      //equityComponent: ,
      status:'Confirmed',
      value:order.shares,
      valuation: OutstandingShares * (order.price??0),
      locked:false,
      isDirty:true
  }
    transactionsBuffer.push(transaction);
    setTransactions(transactionsBuffer);
    setDatabaseStatusRequest("Sync");
    
}


    return (
      <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', m:1}}>


        <TableContainer elevation={5} component={Paper} sx={{p:2, display:'flex', flexDirection:'column'}}>

          <Button variant="outlined" onClick={()=>{loadDBFn();}} sx={{alignSelf:'end', mx:3, my:1}}>↻</Button>

          <Table sx={{ width:'70vw' }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }} align="center">Owner</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="center">Owner Rating</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="center">Share Price</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="center">Shares</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="center">%</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="center">Type</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="center">Status</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

                {/* If user wants to buy, present him orders of type sell */}
              {EquityOrdersState?./*.filter(order=>order.status=="Active")*/filter(order=>order.type=="Buy").sort((a, b)=> {return ((a.price??0) - (b.price??0));})
              .concat(
              EquityOrdersState?./*filter(order=>order.status=="Active").*/filter(order=>order.type=="Sell").sort((a, b)=> {return ((b.price??0) - (a.price??0));}))
              .map((order) => (
              <TableRow key={order.id}>
                <TableCell align="center">{order?.Ownername}</TableCell>
                <TableCell align="center">{getOwnersRating(order?.OwnerId??"")}%</TableCell>
                <TableCell align="center">{order?.price} {currency}</TableCell>
                <TableCell align="center">{order?.shares}</TableCell>
                <TableCell align="center">{((order?.shares??0) / OutstandingShares).toFixed(2)}%</TableCell>
                <TableCell align="center">{order?.type}</TableCell>
                <TableCell align="center">{order?.status}</TableCell>
                <TableCell align="center">



                {order.OwnerId==currentUserId&&(order.status=="Active")&&
                <Button sx={{textTransform: 'none', fontSize:12}} variant="contained" 
                onClick={()=>{
                  setEquityOrdersState(EquityOrdersState.map((orderItem)=>{
                    if(orderItem.id==order.id)
                    {
                      orderItem.status="Deleted";
                      orderItem.isDirty=true;
                      setDatabaseStatusRequest("Sync");
                    }
                    return orderItem;
                  }))
                  
                }}>Delete</Button>
                }

                {/****************************************Buy Orders****************************************
                Sequence: Active - PendingConfirmation - OwnerConfirmed (That money was sent) - Executed*/} 

                {(order.type=="Buy")&&(order.OwnerId!==currentUserId)&&(order.status=="Active")&&
                <Button sx={{textTransform: 'none', fontSize:12}} variant="contained" onClick={() =>{
                  setEquityOrdersState(EquityOrdersState.map((orderItem)=>{
                    if(orderItem.id==order.id)
                    {
                      orderItem.status="PendingConfirmation";
                      order.CounterpartId = currentUserId;
                      order.Counterpartname = currentUserName;
                      orderItem.isDirty=true;
                      setDatabaseStatusRequest("Sync");
                    }
                    return orderItem;
                  }));

                }}>Sell</Button>
                }

                {(order.type=="Buy")&&(order.OwnerId==currentUserId)&&(order.status=="PendingConfirmation")&&
                <Button sx={{textTransform: 'none', fontSize:12}} variant="contained" onClick={() =>{
                  setEquityOrdersState(EquityOrdersState.map((orderItem)=>{
                    if(orderItem.id==order.id)
                    {
                      orderItem.status="OwnerConfirmed";
                      orderItem.isDirty=true;
                      setDatabaseStatusRequest("Sync");
                    }
                    return orderItem;
                  }));
                }}>Confirm Payment</Button>
                }

                {(order.type=="Buy")&&(order.CounterpartId==currentUserId)&&(order.status=="OwnerConfirmed")&&
                <Button sx={{textTransform: 'none', fontSize:12}} variant="contained" onClick={() =>{
                  setEquityOrdersState(EquityOrdersState.map((orderItem)=>{
                    if(orderItem.id==order.id)
                    {
                      orderItem.status="Executed";
                      orderItem.isDirty=true;
                      CreateBuyTransaction(orderItem);
                      setDatabaseStatusRequest("Sync");
                    }
                    return orderItem;
                  }));
                }}>Confirm Receipt</Button>
                }

                {/****************************************Sell Orders****************************************
                Sequence: Active - PendingConfirmation - CounterpartConfirmed (That money was sent) - Executed*/} 

                {(order.type=="Sell")&&(order.OwnerId!==currentUserId)&&(order.status=="Active")&&
                <Button sx={{textTransform: 'none', fontSize:12}} variant="contained" onClick={() =>{
                  setEquityOrdersState(EquityOrdersState.map((orderItem)=>{
                    if(orderItem.id==order.id)
                    {
                      orderItem.status="PendingConfirmation";
                      order.CounterpartId = currentUserId;
                      order.Counterpartname = currentUserName;
                      orderItem.isDirty=true;
                      setDatabaseStatusRequest("Sync");
                    }
                    return orderItem;
                  }));

                }}>Buy</Button>
                }

                {(order.type=="Sell")&&(order.CounterpartId==currentUserId)&&(order.status=="PendingConfirmation")&&
                <Button sx={{textTransform: 'none', fontSize:12}} variant="contained" onClick={() =>{
                  setEquityOrdersState(EquityOrdersState.map((orderItem)=>{
                    if(orderItem.id==order.id)
                    {
                      orderItem.status="CounterpartConfirmed";
                      orderItem.isDirty=true;
                      setDatabaseStatusRequest("Sync");
                    }
                    return orderItem;
                  }));
                }}>Confirm Payment</Button>
                }

                {(order.type=="Sell")&&(order.OwnerId==currentUserId)&&(order.status=="CounterpartConfirmed")&&
                <Button sx={{textTransform: 'none', fontSize:12}} variant="contained" onClick={() =>{
                  setEquityOrdersState(EquityOrdersState.map((orderItem)=>{
                    if(orderItem.id==order.id)
                    {
                      orderItem.status="Executed";
                      orderItem.isDirty=true;
                      setDatabaseStatusRequest("Sync");
                    }
                    return orderItem;
                  }));
                }}>Confirm Receipt</Button>
                }

                </TableCell>
              </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
        </Box>
      );
    }