import { Height, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Autocomplete, AutocompleteChangeDetails, Avatar, Box, Button, Checkbox, Chip, Collapse, Container, FormControlLabel, FormGroup, Grow, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, MobileStepper, Paper, Switch, TextField, TextareaAutosize, ToggleButton, ToggleButtonGroup, Tooltip, Typography, createFilterOptions, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { EquityComponentType, CategoryType, ProjectType, SkillType, projectMembershipType, UserType, TaskStatusType, TaskType, TransactionType } from "./Interfaces";
import useState from 'react-usestateref';
import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, Transaction, where } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useHistory, useParams } from "react-router-dom";
import TargetEquityChart from "./TargetEquityChart";
import UserAvatar from "./UserAvatar";
import DeleteIcon from '@mui/icons-material/Delete';
import { set } from "date-fns";
import { useAuthState } from "react-firebase-hooks/auth";
import Project from "./Project";
import InitEquityAssignmentChart from "./InitEquityAssignmentChart";
import Typewriter from 'typewriter-effect';
import StateChart from "./StateChart";
import useWindowDimensions from "./useWindowDimensions";

export default function LandingPageDemo({scrollPosition}:{scrollPosition:number}){

    const theme = useTheme();

    const [project, setProject, projectRef] = useState<ProjectType>({});
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [newCategory, setNewCategory] = useState<CategoryType>();
    const [skills, setSkills] = useState<SkillType[]>([]);
    const [newSkills, setnewSkills] = useState<SkillType[]>([]);
    const [ProjectsMemberships, setProjectsMemberships, ProjectsMembershipsRef] = useState<projectMembershipType[]>([]);
    const [Users, setUsers] = useState<UserType[]>([]);
    const [StepTooLargeErrorState, setStepTooLargeErrorState] = useState<boolean>(false);
    const [SelectedEquityComponent, setSelectedEquityComponent] = useState<String>("Core Idea");
    const [Transactions, setTransactions, TransactionsRef] = useState<TransactionType[]>([]);

    const [InitializationSequence, setInitializationSequence, InitializationSequenceRef] = useState<number>(0);

    const [task, setTask, TaskRef] = useState<TaskType>({});



    const firstSectionOffsetPx = 200;
    const secondSectionOffsetPx = 1200;
    const thirdSectionOffsetPx = 2200;


    function capitalizeFirstLetter(string:string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const filter = createFilterOptions();
    


    function InitializeProject()
    {
        if(InitializationSequenceRef.current==0)
        {
            setProject({...project, equityComponentList:
                [
            {name:"Core Idea", targetEquityShares:100000, type:'outstanding', locked:false} as EquityComponentType,
            {name:"IP", targetEquityShares:150000, type:'outstanding', locked:false} as EquityComponentType,
            {name:"Cash", targetEquityShares:150000, type:'cash', cash:100000, locked:false} as EquityComponentType,
            {name:"Management", targetEquityShares:150000, type:'reserved', locked:false} as EquityComponentType,
            {name:"Development", targetEquityShares:250000, type:'reserved', locked:false} as EquityComponentType,
            {name:"Marketing", targetEquityShares:100000, type:'reserved', locked:false} as EquityComponentType,
            {name:"Sales", targetEquityShares:100000, type:'reserved', locked:false} as EquityComponentType],
            
            authorizedShares:1000000,
            currentValuation:100000,
            currency:"EGP",
    
            isDirty:true}
            );    

            setInitializationSequence(1);
        }

        else if(InitializationSequenceRef.current==1)
        {
            setProjectsMemberships([{userId: '0', projectId:project.id, 
                memberName: 'Founder 1', status:"active", role:'Admin', isDirty:true}, 
                {userId: '1', projectId:project.id, 
                memberName: 'Founder 2', status:"active", role:'Admin', isDirty:true},
                {userId: '2', projectId:project.id, 
                memberName: 'Founder 3', status:"active", role:'Admin', isDirty:true},
                ]);
    
            setInitializationSequence(2);
        }

        else if(InitializationSequenceRef.current==2)
        {
            setTask({status:'Unassigned'});
            setInitializationSequence(3);
        }

        else if(InitializationSequenceRef.current==3)
        {
            ResetTargetEquities(true);

            setInitializationSequence(4);
        }

    }



    function increaseEquity(equityCompName:string, step:number)
    {
        step = 10**step * (projectRef.current.equityComponentList?.filter((comp)=>(comp.locked==false)).length!-1);

        if(step < (projectRef.current.authorizedShares!))
        {
            setStepTooLargeErrorState(false);

            if(projectRef.current.equityComponentList?.find(comp=>(comp.name==equityCompName))?.locked==false)
            {
                setProject({...projectRef.current, 
                    equityComponentList:
                    projectRef.current.equityComponentList?.map(
                        (comp, idx, list)=>
                        {
                            if(comp.name==equityCompName && comp.locked==false)
                            {
                                comp.targetEquityShares = comp.targetEquityShares!+step;
                            } 
                            if(comp.name!==equityCompName && comp.locked==false)
                            {
                                comp.targetEquityShares = Math.max(0, comp.targetEquityShares!-(step/(list.filter((comp)=>(comp.locked==false)).length-1)));
                            } 
                            return comp;
                        })
                        //.filter((comp:EquityComponentType)=>(comp.targetEquityShares!>0))
                        , isDirty:true})
            }
        }
        else
        {
            setStepTooLargeErrorState(true);
        }
    }

    function toggleLockEquityComponent(equityCompName:string)
    {
        setProject({...projectRef.current, 
            equityComponentList:
            projectRef.current.equityComponentList?.map(
                (comp, idx, list)=>
                {
                    if(comp.name==equityCompName)
                    {
                        comp.locked = !comp.locked;
                    }
                    return comp;
                })
                , isDirty:true})
    }


    function ResetTargetEquities(TotalSharesNotOutstanding:boolean)
    {
            let targetShares = projectRef.current.authorizedShares!;
            let sharesPerComp = Math.ceil(targetShares/(projectRef.current.equityComponentList!.length));
            setProject({...projectRef.current, 
                equityComponentList:
                projectRef.current.equityComponentList?.map(
                    (comp, idx, list)=>
                    {
                        if(targetShares>sharesPerComp)
                        {
                            comp.targetEquityShares = sharesPerComp;
                            targetShares -= sharesPerComp;
                        }
                        else
                        {
                            comp.targetEquityShares = targetShares;
                        }

                        comp.locked = false;
                        return comp;
                    })
                    , isDirty:true});
    
                    setStepTooLargeErrorState(false);    
    }

    function PeriodicFunction()
    {   
    }

    function setEquityCompFocus(targetcomp:string)
    {   
        console.log(targetcomp)
        if(targetcomp!= null)
        {
            setProject({...project, equityComponentList:project.equityComponentList?.map((comp, idx, list)=>{
                if(comp.name==targetcomp)
                {
                    comp.focus=true;
                }
                else
                {
                    comp.focus=false;
                }
                return comp;
            })})                                
        }
    }

    function allowedStatus(currentState:string)
    {
        let statusList:string[] = [];
        if(currentState == "Unassigned")
        {
            statusList = ["Unassigned", "Active"];
        }
        else if (currentState == "Active")
        {
            statusList = ["Active", "InReview", "Blocked", "Failed"];
        }
        else if (currentState == "InReview")
        {
            statusList = ["InReview", "Done", "Active"];
        }
        else if (currentState == "Done")
        {
            statusList = ["Done"];
        }
        else if (currentState == "Blocked")
        {
            statusList = ["Blocked", "Active", "Failed"];
        }
        else if (currentState == "Failed")
        {
            statusList = ["Failed"];
        }
        return statusList;
    }


    
    useEffect(
        ()=>{
            const interval = setInterval(() => {
                PeriodicFunction();
            },2*1000);
            return () => clearInterval(interval);
        },[]);

        useEffect(
            ()=>{
                const interval = setInterval(() => {

                    if(InitializationSequenceRef.current<4)
                    {
                        InitializeProject();
                    }
                    else
                    {
                        clearInterval(interval);
                    }

                    console.log(InitializationSequenceRef.current)

                    },10);
                return () => clearInterval(interval);
            },[]);
    

    return(
        <Box sx={{display:'flex', flexDirection:'column'}}>

//=====================First section =====================

        <Box sx={{display:'flex', flexDirection:(useWindowDimensions().width>800)?'row':'column'}}>

            <Grow
            in={scrollPosition>firstSectionOffsetPx}
            style={{ transformOrigin: 'top' }}
            {...({ timeout: 2000 })}
            >
            <Box 
        sx={{            
            p: 2,
            my: 5,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            //alignSelf: 'start',
            //justifySelf: "start",
            //minHeight: '75vh',
            minWidth:'60vw',
            backgroundColor: '#FAF9F6'
        }}>

            <Typography variant="caption" color={'black'} sx={{width:'50%', textAlign:"center", alignSelf:'center'}}>Click on a component to toggle it between Outstanding, Reserved and Cash.</Typography>
            <Autocomplete
                multiple
                sx={{minWidth:'50%', m:1, alignSelf:'center'}}

                onChange={(event:any, updatedList:any[], reason, details) => {
                        console.log(updatedList)
                        console.log(reason)
                        console.log(details)

                        if(reason == "createOption")
                        {
                            setProject({...project, equityComponentList:
                                [...(project.equityComponentList!) , ({name:details?.option, locked:false, type:"outstanding", focus:false} as EquityComponentType) ], isDirty:true});    
                        }
                        else if(reason == "removeOption")
                        {
                            setProject({...project, equityComponentList:
                                project.equityComponentList!.filter(comp=>(comp.name !== details?.option))});
                        }
                        
                        ResetTargetEquities(true);
                    
                }}
                value = {project?.equityComponentList?.map(comp=>comp.name)||[]}
                id="tags-filled"
                options={[]}
                freeSolo
                renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option} 
                    sx={{
                    color:(project.equityComponentList?.find(comp=>(comp.name==option))?.type=="cash")?'green':
                    (project.equityComponentList?.find(comp=>(comp.name==option))?.type=="outstanding")?'darkred':'blue',
                    borderColor:(project.equityComponentList?.find(comp=>(comp.name==option))?.type=="cash")?'green':
                    (project.equityComponentList?.find(comp=>(comp.name==option))?.type=="outstanding")?'darkred':'blue',
                    borderWidth:(project.equityComponentList?.find(comp=>(comp.name==option))?.focus==true)? 2:1,
                    fontWeight:(project.equityComponentList?.find(comp=>(comp.name==option))?.focus==true)? "bolder":"auto",
                }}
                    onClick={(e:any)=>{
                        setProject({...project, equityComponentList:project.equityComponentList?.map((comp, idx, list)=>{
                            if(comp.name==e.target.textContent)
                            {
                                if(comp.focus == true)
                                    {
                                        if(comp.type=="outstanding")
                                            {
                                                comp.type = "reserved";
                                            }
                                            else if(comp.type=="reserved")
                                            {
                                                comp.type = "cash";
                                            }
                                            else if(comp.type=="cash")
                                            {
                                                comp.type = "outstanding";
                                            }
                                    }
                                comp.focus = true;
                            }
                            else
                            {
                                comp.focus = false;
                            }
                            return comp;
                        }), isDirty:true});
                                                                    
                    }} 
                    
                        {...getTagProps({ index })} />
                ))
                }
                renderInput={(params) => (
                <TextField sx={{width:'100%'}}
                    {...params}
                    variant="filled"
                    label="Equity Components"
                    placeholder=""
                />
                )}
                />
                <Box width='25%' alignSelf={"center"} sx={{display:"flex", flexDirection:'row', mt:1, justifyContent:'space-evenly'}}>
                    <Chip variant="outlined" label={"Outstanding"} sx={{color:'darkred', borderColor:'darkred'}} />
                    <Chip variant="outlined" label={"Reserved"} sx={{color:'blue', borderColor:'blue'}} />
                    <Chip variant="outlined" label={"Cash"} sx={{color:'green', borderColor:'green'}} />
                </Box>

            {project.equityComponentList?.length &&
            <TargetEquityChart equityComps={project.equityComponentList!} 
            setEquityCompFocus = {setEquityCompFocus}
            authorizedShares={project.authorizedShares!}
            IncreaseEquityCallback={increaseEquity} 
            ToggleLockCallback={toggleLockEquityComponent}
            ResetEquitiesCallback={() => ResetTargetEquities(true)}
            StepTooLargeError={StepTooLargeErrorState}/>
            }
        </Box>
        </Grow>

        <Grow
            in={scrollPosition>firstSectionOffsetPx}
            style={{ transformOrigin: 'top' }}
            {...({ timeout: 2000 })}
            >
        <Paper elevation={0}
            sx={{p: 2,
            my: 5, 
            mx:2,
            background:'white',
            //minHeight: '30vh',
            minWidth:'30vw',
            minHeight:'30vh',
            alignSelf:'center',
            alignContent:'center',
            //justifySelf:'center',
            backgroundColor: '#FAF9F6'}}
        >

            {(scrollPosition>firstSectionOffsetPx) &&
            // <Typewriter
            // onInit={(typewriter) => {
            //     typewriter.changeDelay(17).deleteAll().typeString("Define your project's equity components, the main competencies, resources and activities necessary to implement the project, and decide the conditions and percentages of how equity is to be divided among these equity components  ..")
            //     .start();
            //     }
            // }
            // />

            <Typography fontSize={20}> Define your project's equity components, the main competencies, resources and activities necessary to implement the project, and decide the conditions and percentages of how equity is to be divided among these equity components  .. </Typography>
            }
        </Paper>
        </Grow>
        </Box>


//=====================Second section =====================


        <Box sx={{display:'flex', flexDirection:(useWindowDimensions().width>800)?'row':'column'}}>

        <Grow
            in={scrollPosition>secondSectionOffsetPx}
            style={{ transformOrigin: 'top' }}
            {...({ timeout: 2000 })}
            >
        <Paper elevation={0}
            sx={{p: 2,
            my: 5, 
            mx:2,
            background:'white',
            //minHeight: '30vh',
            minWidth:'30vw',
            minHeight:'30vh',
            alignSelf:'center',
            alignContent:'center',
            //justifySelf:'center',
            backgroundColor: '#FAF9F6'}}
        >

            {(scrollPosition>secondSectionOffsetPx) &&
            // <Typewriter
            // onInit={(typewriter) => {
            //     typewriter.changeDelay(17).deleteAll().typeString('Decide how outstanding shares are to be distributed amoung founding members ..')
            //     .start();
            //     }
            // }
            // />

            <Typography fontSize={20}>Decide how outstanding shares are to be distributed amoung founding members .. </Typography>

            }
        </Paper>
        </Grow>

        <Grow
        in={scrollPosition>secondSectionOffsetPx}
        style={{ transformOrigin: 'top' }}
        {...({ timeout: 2000 })}
        >

        <Box 
        sx={{            
            p: 2,
            my: 5,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            //alignSelf: 'end',
            //justifySelf: "end",
            //minHeight: '75vh',
            minWidth:'60vw',
            backgroundColor: '#FAF9F6'
        }}>
                
            {project.equityComponentList?.length &&
            <InitEquityAssignmentChart equityComps={project.equityComponentList??([] as EquityComponentType[])} 
            projectMemberships={ProjectsMemberships} Transactions={TransactionsRef.current} setTransactions={setTransactions}
            project = {project!}/>
            }
        </Box>
        </Grow>
        </Box>

//=====================Third section =====================


        <Box sx={{display:'flex', flexDirection:(useWindowDimensions().width>800)?'row':'column'}}>

        <Grow
        in={scrollPosition>thirdSectionOffsetPx}
        style={{ transformOrigin: 'top' }}
        {...({ timeout: 2000 })}
        >
        <Box 
        sx={{            
        p: 2,
        my: 5,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5,
        //alignSelf: 'start',
        //justifySelf: "start",
        //minHeight: '75vh',
        minWidth:'60vw',
        backgroundColor: '#FAF9F6'
        }}>

            <StateChart State={task.status!} nextStateList={allowedStatus(task.status!)}
            changeStateHandler={(newState:TaskStatusType)=>{
                    if(newState!==task.status)
                    {
                        setTask({...task, status:newState, isDirty:true});
                    }
                }}/>
        </Box>
        </Grow>

        <Grow
        in={scrollPosition>thirdSectionOffsetPx}
        style={{ transformOrigin: 'top' }}
        {...({ timeout: 2000 })}
        >
        <Paper elevation={0}
        sx={{p: 2,
        my: 5, 
        mx:2,
        background:'white',
        //minHeight: '30vh',
        minWidth:'30vw',
        minHeight:'30vh',
        alignSelf:'center',
        alignContent:'center',
        //justifySelf:'center',
        backgroundColor: '#FAF9F6'}}
        >

        {(scrollPosition>thirdSectionOffsetPx) &&
        // <Typewriter
        // onInit={(typewriter) => {
        //     typewriter.changeDelay(17).deleteAll().typeString("Define tasks and manage and track their progress, tasks quality of execution decide how reserved shares are to be distributed among contributing members, ensuring a fair and dynamic equity distribution ..")
        //     .start();
        //     }
        // }
        // />

        <Typography fontSize={20}>Define tasks and manage and track their progress, tasks execution quality decide how reserved shares are to be distributed among contributing members, ensuring a fair and dynamic equity distribution ..</Typography>

        }
        </Paper>
        </Grow>
        </Box>


        </Box>
    );

}
